import './index.css';
import './tracker';
import $ from 'jquery';
import ipad from './images/ipad.png';
import screen1 from './images/screen1.png';
import screen2 from './images/screen2.png';
import screen3 from './images/screen3.png';
import screen4 from './images/screen4.png';
import screen5 from './images/screen5.png';

global.jQuery = $;
global.$ = $;

(function($){
	$.fn.typographicbg = function(options) {
		return this.each(function() {
			var selector = $(this);
			var padding = options.padding;

			$(selector).each(function(){      					
				var string = $(this).text().split(' ');
				var word = $(this);
				$(this).text('');
				$.each(string, function(){
					word.append('<span>'+this+' </span>');
				});

				var me = $(this);
				setTimeout( (function(me){return function(){
					me.find('span:first-child').css('padding-left', padding);
					me.find('span:last-child').css('padding-right', padding);

					var startingheight = me.find('span').position().top;
					me.find('span').each(function(){
						var thisheight = $(this).position().top;				
						if (thisheight > startingheight) {
							$(this).attr('data-ypos','height is: '+thisheight);
							startingheight = thisheight;
							$(this).css('padding-left', padding);
							$(this).prev().css('padding-right', padding);
						}                           
					});
				};})(me), 1000);
			});
		});
	};
})($);			

global.pictureMover = function(){
	var pub = {};			

	var center;				
	var paused = false;

	var animateToCenter = function(img, complete){
		img.animate({
						height:399,
						left:center + 39,
						top:44
			}, 450, complete)
	}

	var animateOffCenter = function(img, complete){
		var left = img.position().left + 39;
					
		img.animate({	height:317,
						top:88,
						left:left - 10				
					}, 450, complete);
	};

	var animateTo = function(obj, toObj, complete){
		obj.animate({
			left: toObj.position().left,
			opacity: toObj.css('opacity')									
		}, 1000, complete);
	};				

	var rotate = function(first, second, third, fourth, fifth){		
		if ( !paused ){
			animateOffCenter(first, function(){														
					animateTo(first, fourth);
					animateTo(fourth, fifth);
					animateTo(second, first, function(){
						animateToCenter(second);
					});
					animateTo(third, second);
							
					fifth.offset({left:third.position().left + third.width() + 20  });
					animateTo(fifth, third);							
				});	
		}
													
		var timer = setTimeout(function(){
			clearTimeout(timer);
			if ( paused ) {
				rotate(first, second, third, fourth, fifth);
			}
			else{
				rotate(second, third, fifth, first, fourth);
			}
		}, 5000)
	};
					
	pub.pause = function(){
		paused = true;
	};

	pub.reset = function(){
		paused = false;				
	};

	pub.init = function(){
		$(window).focus(function() {
			pub.reset();
		});
	
		$(window).blur(function() {						
			pub.pause();
		});			
						
		paused = false;

		var wrapper = $('#wrapper');
		wrapper.empty();
						
		center = (wrapper.width() /2 ) - 187;

		$("<img src='" + ipad + "'>")
			.css({position:'absolute', zIndex:100})
			.offset({left:center, top:0})
            .appendTo('#wrapper');		
            
		var first = $("<img src='" + screen1 + "'>")
						.css({position:'absolute', height:399})
						.offset({left:center + 39, top:44})
						.appendTo('#wrapper');

		var second = $("<img src='" + screen2 + "'>")
						.css({position:'absolute', height:317, opacity:.4, zIndex:0})
						.offset({left:center + 350, top:88})
						.appendTo('#wrapper');

		var third = $("<img src='" + screen3 + "'>")
						.css({position:'absolute', height:317, opacity:.1, zIndex:0})
						.offset({left:center + 600, top:88})
						.appendTo('#wrapper');

		var fourth = $("<img src='" + screen4 + "'>")
						.css({position:'absolute', height:317, opacity:.4, zIndex:0})
						.offset({left:center -210, top:88})
						.appendTo('#wrapper');

		var fifth = $("<img src='" + screen5 + "'>")
						.css({position:'absolute', height:317, opacity:.1, zIndex:0})
						.offset({left:center -460, top:88})
						.appendTo('#wrapper');

		setTimeout(function(){
			rotate(first, second, third, fourth, fifth);																
		}, 2000);
	}

	return pub;				
}();												

$(function() {
    var setupSpacing = function() {
        $('#quote1 > span').typographicbg({
            padding: '5px'
        });
        $('#quote2 > span').typographicbg({
            padding: '5px'
        });
        $('#quote3 > span').typographicbg({
            padding: '5px'
        });
    };

    $(window).resize(function() {
        setupSpacing();
    });

    setupSpacing();
    global.pictureMover.init();
});